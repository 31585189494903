/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.sw-country-svg {
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  height: 1em;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 1em;
  line-height: 1em;
}
.sw-lang-select {
  cursor: pointer;
}
.sw-lang-select-menu .sw-lang-select-item .sw-country-emoji,
.sw-lang-select-menu .sw-lang-select-item .sw-country-svg {
  margin-right: 8px;
  vertical-align: middle;
}
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix {
  font-size: 18px;
  cursor: pointer;
}
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.input-focused,
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.input-focused.input-hovered,
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.anticon:active,
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.anticon:hover {
  color: #40a9ff;
}
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.input-hovered,
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.input-hovered:hover {
  color: rgba(24, 144, 255, 0.5);
}
.sw-input-password.ant-input-affix-wrapper .sw-input-password-suffix.hidden {
  display: none;
  visibility: hidden;
}
.sw-form-actions .sw-form-action {
  float: right;
}
.sw-form-actions .sw-form-action:not(:first-of-type) {
  margin-right: 10px;
}
.sw-auth-view {
  height: 1px;
  min-height: 100vh;
  background: linear-gradient(to bottom, #096dd9 50%, #1890ff 100%);
  background-size: cover;
  background-repeat: no-repeat;
}
.sw-auth-view .sw-auth-view-lang {
  padding: 8px 16px;
}
.sw-auth-view .sw-auth-view-lang .sw-auth-view-lang-col {
  text-align: right;
}
.sw-auth-view .sw-auth-box {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  background-color: #f0f2f5;
  padding: 1em;
}
.sw-auth-main .sw-auth-title {
  text-align: center;
}
.sw-auth-main .sw-auth-btn {
  margin-bottom: 2.5px;
}
.sw-auth-main .sw-auth-btn:not(:first-of-type) {
  margin-top: 2.5px;
}
.sw-login-lock.ant-avatar {
  background-color: #ff4d4f;
}
.sw-login-forgot-field-link {
  text-align: right;
}
.sw-login-forgot-btn {
  display: inherit;
  margin-top: 2.5px;
  text-align: center;
}
.sw-reg-form .sw-reg-login-btn {
  display: inherit;
  margin-top: 2.5px;
  text-align: center;
}
.ant-layout.sw-layout .sw-layout-inner {
  min-height: 100vh;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-layout.sw-layout.sw-layout-b .sw-layout-inner {
  min-height: calc(100vh - 64px);
}
.ant-layout.sw-layout .sw-layout-footer {
  padding: 0;
}
.ant-layout.sw-layout .sw-layout-footer .sw-footer-main {
  padding: 0 16px;
  margin: 24px 0;
  text-align: center;
}
.ant-layout.sw-layout .sw-layout-content {
  margin: 24px;
}
.ant-layout.sw-layout .sw-layout-content.sw-layout-content-fixed {
  padding-top: 64px;
}
.ant-layout.sw-layout .sw-layout-content .sw-layout-basic-content {
  margin: -24px;
}
.sw-page-content {
  margin: 24px 24px 0;
}
.sw-page-content .sw-page-main {
  width: 100%;
  transition: 0.3s;
}
@media screen and (max-width: 576px) {
  .sw-page-content {
    margin: 24px 0 0;
  }
}
.sw-page-card {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.sw-page-header {
  background: #fff;
  padding: 16px 24px 0;
  border-bottom: 1px solid #e8e8e8;
}
.sw-page-header .sw-page-breadcrumb {
  margin-bottom: 16px;
}
.sw-page-header .sw-page-breadcrumb .anticon {
  margin-right: 4px;
}
.sw-page-header .sw-page-header-detail {
  display: flex;
}
.sw-page-header .sw-page-header-detail .sw-page-detail-container {
  flex: 0 1 auto;
  width: 100%;
}
.sw-page-header .sw-page-header-detail .sw-page-detail-container .sw-page-header-row {
  display: flex;
  width: 100%;
}
.sw-page-header .sw-page-header-detail .sw-page-detail-container .sw-page-header-row .sw-page-title {
  flex: auto;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.ant-layout-sider.sw-side-nav .sw-nav-logo,
.ant-layout-header.sw-layout-header .sw-nav-logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  transition: all 0.3s;
  background: #002140;
  overflow: hidden;
}
.ant-layout-sider.sw-side-nav .sw-nav-logo img,
.ant-layout-header.sw-layout-header .sw-nav-logo img {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
}
.ant-layout-sider.sw-side-nav .sw-nav-logo h1,
.ant-layout-header.sw-layout-header .sw-nav-logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-weight: 600;
}
.ant-layout-header.sw-layout-header {
  padding: 0;
  width: 100%;
}
.ant-layout-header.sw-layout-header.sw-layout-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
}
.ant-layout-header.sw-layout-header .sw-top-nav {
  height: 64px;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: row;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-nav-logo {
  width: 256px;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-side-nav-trigger {
  font-size: 20px;
  height: 64px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: 22px 24px;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-menu {
  flex: 1;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-menu.ant-menu-horizontal {
  border-bottom: none;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-lang-select,
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
  padding: 0 12px;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop .sw-user-drop-avatar {
  margin: 20px 8px 20px 0;
  vertical-align: top;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop .sw-user-drop-detail.has-role {
  display: inline-block;
  vertical-align: top;
  margin: 16px 0;
}
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop .sw-user-drop-detail.has-role .sw-user-drop-name,
.ant-layout-header.sw-layout-header .sw-top-nav .sw-top-nav-items .sw-user-drop .sw-user-drop-detail.has-role .sw-user-drop-role {
  display: block;
  line-height: 16px;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-side-nav-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-top-nav-items .ant-dropdown-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-nav-logo {
  background: #fff;
  border-right: solid 1px #e8e8e8;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-nav-logo h1 {
  color: #1890ff;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-light .sw-nav-logo h1:hover {
  color: #40a9ff;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-dark {
  background: #001529;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  color: rgba(255, 255, 255, 0.65);
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-dark .sw-side-nav-trigger:hover {
  color: #fff;
  background: #1890ff;
}
.ant-layout-header.sw-layout-header .sw-top-nav.sw-top-nav-dark .sw-top-nav-items .ant-dropdown-trigger:hover {
  color: #fff;
  background: #1890ff;
}
.ant-layout.sw-layout.sw-layout-b .ant-layout-header.sw-layout-header {
  z-index: 11;
}
.ant-layout-sider.sw-side-nav {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-layout-sider.sw-side-nav .sw-side-nav-menu {
  padding-bottom: 16px;
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed {
  position: fixed;
  top: 0;
  left: 0;
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed .ant-menu-root {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 64px);
}
.ant-layout-sider.sw-side-nav.sw-side-nav-fixed .ant-menu-root.is-mobile {
  height: calc(100vh - 110px);
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light .sw-nav-logo {
  background: #fff;
  box-shadow: 1px 1px 0 0 #e8e8e8;
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light .sw-nav-logo h1 {
  color: #1890ff;
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light .sw-nav-logo h1:hover {
  color: #40a9ff;
}
.ant-layout-sider.sw-side-nav.ant-layout-sider-light .ant-layout-sider-trigger {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
.ant-drawer.sw-drawer-side-nav .ant-drawer-body {
  padding: 0;
  height: 100vh;
}
.ant-layout.sw-layout.sw-layout-b .ant-layout-sider.sw-side-nav.sw-side-nav-fixed {
  top: 64px;
}
.sw-page-error {
  text-align: center;
  display: block;
  position: relative;
  width: 450px;
  margin: 64px auto;
}
.sw-page-error-code {
  color: #1890ff;
  font-size: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 250px;
  letter-spacing: 15px;
}
.sw-page-error hr {
  padding: 0;
  border: none;
  border-top: 5px solid rgba(0, 0, 0, 0.85);
  text-align: center;
  margin: 8px auto;
  width: 420px;
  height: 10px;
  z-index: -10;
}
.sw-page-error hr::after {
  content: '\2022';
  display: inline-block;
  position: relative;
  top: -0.85em;
  font-size: 2em;
  padding: 0 0.2em;
  background: #f0f2f5;
  color: rgba(0, 0, 0, 0.85);
}
.sw-page-error-1 {
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: 12px;
  font-size: 4em;
  line-height: 80%;
  text-transform: uppercase;
}
.sw-page-error-2 {
  text-align: center;
  display: block;
  position: relative;
  font-size: 20px;
  text-transform: uppercase;
}
.sw-page-error-btn {
  position: relative;
  display: inline-block;
  width: 358px;
  margin-top: 8px;
  text-transform: uppercase;
}
.sw-table-root,
.sw-table-tools,
.sw-table-container {
  width: 100%;
}
.sw-table-root.is-mobile,
.sw-table-tools.is-mobile,
.sw-table-container.is-mobile {
  max-height: 100%;
}
.sw-table-tools {
  display: flex;
  flex-direction: column;
}
.sw-table-tools .sw-table-info {
  margin-top: 12px;
}
.sw-table-tools .sw-table-tools-top {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-actions {
  flex: 0 0 auto;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons {
  flex: auto;
  text-align: right;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input,
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input-affix-wrapper,
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input-group-wrapper {
  max-width: 320px;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input .ant-input-suffix,
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input-affix-wrapper .ant-input-suffix,
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-input-group-wrapper .ant-input-suffix {
  cursor: pointer;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .anticon {
  font-size: 16px;
  padding: 6px 0;
  vertical-align: middle;
  cursor: pointer;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .anticon:hover {
  color: #40a9ff;
}
.sw-table-tools .sw-table-tools-top .sw-table-tools-buttons > .ant-divider-vertical {
  height: 1.1em;
  margin: 0 6px;
}
.sw-table-filter-overlay .sw-table-filter-reset,
.sw-table-query-overlay .sw-table-query-reset,
.sw-table-actions-mobile {
  cursor: pointer;
}
.sw-table-container .sw-column-action .sw-row-action-item-icon {
  font-size: 16px;
}
.sw-table-container .sw-column-action .sw-row-action-item {
  color: #1890ff;
  text-decoration: none;
}
.sw-table-container .sw-column-action .sw-row-action-item,
.sw-table-container .sw-column-action .sw-row-action-item-icon {
  cursor: pointer;
}
.sw-table-container .sw-column-action .sw-row-action-item:hover,
.sw-table-container .sw-column-action .sw-row-action-item-icon:hover {
  color: #40a9ff;
}
.sw-table-container .sw-column-action .anticon.ant-dropdown-trigger {
  cursor: pointer;
  color: #1890ff;
  text-decoration: none;
  font-size: 16px;
}
.sw-table-container .sw-column-action .anticon.ant-dropdown-trigger:hover {
  color: #40a9ff;
}
